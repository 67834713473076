@import-normalize;
@import "styles/fonts.scss";

*,
*::before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  min-height: 100vh;
}

.flex {
  display: flex;
}

h1, h2, h3, h4 {
  margin: 0;

  &.bold {
    font-weight: 600;
  }
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 1.25rem;
}

p {
  font-size: 1rem;
}